import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
// import Home from '../views/Home.vue';
// import NotFound from '../views/404.vue';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/index.html",
  //   name: "Home",
  //   component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  // },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      },
      //   ],
      // },
      // {
      //   path: '/about',

      //   component: () =>
      //     import(/* webpackChunkName: "layout" */ '../layouts/MainLayout.vue'),
      //   children: [
      //     // { 路由重定向配置
      //     //   path: '/about',
      //     //   redirect: '/about/news'
      //     // },
      {
        path: "/about",
        name: "about index",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About/Index.vue"),
      },
      {
        path: "/about/news",
        name: "about news",
        component: () =>
          import(/* webpackChunkName: "about-news" */ "../views/About/News.vue"),
      },
      //   ],
      // },
      // {
      //   path: '/resource',
      //   component: () =>
      //     import(/* webpackChunkName: "layout" */ '../layouts/MainLayout.vue'),
      //   children: [
      // {
      //   path: '/resource/help',
      //   name: 'resource index',
      //   component: () =>
      //     import(/* webpackChunkName: "resource-help" */ '../views/Resourse/Index.vue'),
      //   children: [
      //     {
      //       path: '/resource/search',
      //       name: 'resource-search', // 出海资源导航
      //       component: () =>
      //         import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/Search.vue'),
      //     },
      //     {
      //       path: '',
      //       name: 'resource-doc', // 出海资源导航
      //       component: () =>
      //         import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/Help.vue'),
      //     },
      //     {
      //       path: '/resource/help/guide',
      //       name: 'resource-guide', // 出海资源导航
      //       component: () =>
      //         import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/Guide.vue'),
      //       children: [
      //         {
      //           path: '/resource/help/guide/:id',
      //           name: 'resource-guide-detail', // 出海资源导航
      //           component: () =>
      //             import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/GuideDetail.vue'),
      //         },
      //       ]
      //     },
      //     {
      //       path: '/resource/help/qa',
      //       name: 'resource-qa', // 出海资源导航
      //       component: () =>
      //         import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/Qa.vue'),
      //       children: [
      //         {
      //           path: '/resource/help/qa/list-:id',
      //           name: 'resource-qa-list', // 出海资源导航
      //           component: () =>
      //             import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/QaList.vue'),
      //         },
      //         {
      //           path: '/resource/help/qa/:cid/:did',
      //           name: 'resource-qa-detail', // 出海资源导航
      //           component: () =>
      //             import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/QaDetail.vue'),
      //         },
      //       ]
      //     },
      //   ]
      // },
      // {
      //   path: '/resource/navigation',
      //   name: 'resource-navigation', // 出海资源导航
      //   component: () =>
      //     import(/* webpackChunkName: "resource-navigation" */ '../views/Resourse/Navigation.vue'),
      // },
      // {
      //   path: '/resource/template',
      //   name: 'resource-template', // 出海资源导航
      //   component: () =>
      //     import(/* webpackChunkName: "resource-template" */ '../views/Resourse/Template.vue'),
      // },
      {
        path: "/resource/help",
        name: "resource index",
        component: () =>
          import(/* webpackChunkName: "resource-help" */ "../views/Resourse/Index.vue"),
        children: [
          {
            path: "/resource/search",
            name: "resource-search", // 出海资源导航
            component: () =>
              import(/* webpackChunkName: "resource-search" */ "../views/Resourse/Search.vue"),
          },
          {
            path: "/resource/help",
            name: "resource-doc", // 出海资源导航
            component: () =>
              import(/* webpackChunkName: "resource-help" */ "../views/Resourse/Help.vue"),
          },
          {
            path: "/resource/help/guide",
            name: "resource-guide", // 出海资源导航
            component: () =>
              import(/* webpackChunkName: "resource-navigation" */ "../views/Resourse/Guide.vue"),
            children: [
              {
                path: "/resource/help/guide/:did",
                name: "resource-guide-detail", // 出海资源导航
                component: () =>
                  import(/* webpackChunkName: "resource-qa-detail" */ "../views/Resourse/GuideDetail.vue"),
              },
            ],
          },
          {
            path: "/resource/help/qa",
            name: "resource-qa", // 出海资源导航
            component: () =>
              import(/* webpackChunkName: "resource-navigation" */ "../views/Resourse/Qa.vue"),
            children: [
              {
                path: "/resource/help/qa/list-:id",
                name: "resource-qa-list", // 出海资源导航
                component: () =>
                  import(/* webpackChunkName: "resource-navigation" */ "../views/Resourse/QaList.vue"),
              },
              {
                path: "/resource/help/qa/:did",
                name: "resource-qa-detail", // 出海资源导航
                component: () =>
                  import(/* webpackChunkName: "resource-qa-detail" */ "../views/Resourse/QaDetail.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/resource/navigation",
        name: "resource-navigation", // 出海资源导航
        component: () =>
          import(/* webpackChunkName: "resource-navigation" */ "../views/Resourse/Navigation.vue"),
      },
      {
        path: "/resource/template",
        name: "resource-template", // 出海资源导航
        component: () =>
          import(/* webpackChunkName: "resource-template" */ "../views/Resourse/Template.vue"),
      },

      //   ],
      // },
      // {
      //   path: '/product',
      //   name: 'Product',
      //   component: () =>
      //     import(/* webpackChunkName: "layout" */ '../layouts/MainLayout.vue'),
      //   children: [
      {
        path: "/product/iexpress",
        name: "InquiryExpress",
        component: () =>
          import(/* webpackChunkName: "express" */ "../views/Product/InquiryExpress.vue"),
      },
      {
        path: "/product/iserver",
        name: "InquiryServer",
        component: () =>
          import(/* webpackChunkName: "server" */ "../views/Product/InquiryServer.vue"),
      },
      {
        path: "/product/igeek",
        name: "TradeGeek",
        component: () =>
          import(/* webpackChunkName: "server" */ "../views/Product/tradeGeek.vue"),
      },
      {
        path: "/product/imarket",
        name: "InquiryMarket",
        component: () =>
          import(/* webpackChunkName: "market" */ "../views/Product/InquiryMarket.vue"),
      },
      {
        path: "/product/brand",
        name: "about index",
        component: () =>
          import(/* webpackChunkName: "brand" */ "../views/Product/Brand.vue"),
      },
      {
        path: "/product/customs",
        name: "customs",
        component: () =>
          import(/* webpackChunkName: "customs" */ "../views/Product/Customs.vue"),
      },
      {
        path: "/product/search-customer",
        name: "search-customer",
        component: () =>
          import(/* webpackChunkName: "search-customer" */ "../views/Product/SearchCustomer.vue"),
      },
      {
        path: "/product/intelligent-search",
        name: "intelligent-search",
        component: () =>
          import(/* webpackChunkName: "intelligent-search" */ "../views/Product/IntelligentSearch.vue"),
      },
      {
        path: "/product/scrm",
        name: "scrm",
        component: () =>
          import(/* webpackChunkName: "scrm" */ "../views/Product/Scrm.vue"),
      },
      {
        path: "/product/intelligent-marketing",
        name: "intelligent-marketing",
        component: () =>
          import(/* webpackChunkName: "scrm" */ "../views/Product/Scrm.vue"),
      },
      {
        path: "/product/mail",
        name: "mail",
        component: () =>
          import(/* webpackChunkName: "scrm" */ "../views/Product/Scrm.vue"),
      },
      {
        path: "/product/global",
        name: "global",
        component: () =>
          import(/* webpackChunkName: "scrm" */ "../views/Product/Scrm.vue"),
      },
      {
        path: "/product/im",
        name: "im",
        component: () =>
          import(/* webpackChunkName: "scrm" */ "../views/Product/Scrm.vue"),
      },
      {
        path: "/product/customer-leads",
        name: "customer-leads",
        component: () =>
          import(/* webpackChunkName: "customer-leads" */ "../views/Product/CustomerLeads.vue"),
      },
      {
        path: "/product/customer-recommend",
        name: "customer-recommend", //
        component: () =>
          import(/* webpackChunkName: "customer-leads" */ "../views/Product/CustomerLeads.vue"),
      },
      {
        path: "/product/customer-inquiry",
        name: "customer-inquiry", // 客户询盘
        component: () =>
          import(/* webpackChunkName: "customer-inquiry" */ "../views/Product/CustomerInquiry.vue"),
      },
      //   ],
      // },
      // {
      //   path: '/school',
      //   name: 'school',
      //   component: () =>
      //     import(/* webpackChunkName: "layout" */ '../layouts/MainLayout.vue'),
      //   children: [
      {
        path: "/school",
        name: "SchoolCenter",
        component: () =>
          import(/* webpackChunkName: "school-center" */ "../views/School/Center.vue"),
      },
      {
        path: "/school/latest",
        name: "school-latest",
        component: () =>
          import(/* webpackChunkName: "school-latest" */ "../views/School/Latest.vue"),
      },
      {
        path: "/school/industry",
        name: "school-industry",
        component: () =>
          import(/* webpackChunkName: "school-industry" */ "../views/School/Industry.vue"),
      },
      {
        path: "/school/market",
        name: "school-market",
        component: () =>
          import(/* webpackChunkName: "school-market" */ "../views/School/Market.vue"),
      },
      {
        path: "/school/product-news",
        name: "product-news",
        component: () =>
          import(/* webpackChunkName: "product-news" */ "../views/School/ProductNews.vue"),
      },
      {
        path: "/school/classroom",
        name: "school-classroom",
        component: () =>
          import(/* webpackChunkName: "school-classroom" */ "../views/School/Classroom.vue"),
      },
      {
        path: "/school/video/:id",
        name: "school-classroom-details",
        component: () =>
          import(/* webpackChunkName: "school-classroom" */ "../views/School/ClassroomDetails.vue"),
      },
      {
        path: "/school/live-stream",
        name: "live-stream",
        component: () =>
          import(/* webpackChunkName: "live-stream" */ "../views/School/LiveStream.vue"),
      },
      // {
      //   path: '/school/content/:id',
      //   name: 'school-content',
      //   component: () =>
      //     import(/* webpackChunkName: "ab-school-content" */ '../views/School/Content.vue'),
      // },
      //   ],
      // },
      // {
      //   path: '/customer',
      //   name: 'MainLayout',
      //   component: () =>
      //     import(/* webpackChunkName: "layout" */ '../layouts/MainLayout.vue'),
      //   children: [
      {
        path: "/customer",
        name: "customer",
        component: () =>
          import(/* webpackChunkName: "customer" */ "../views/Customer/Index.vue"),
      },
      {
        path: "/customer/detail",
        name: "customer detail",
        component: () =>
          import(/* webpackChunkName: "customer-selectedcases" */ "../views/Customer/Detail.vue"),
      },
      //   ],
      // },
      // {
      //   path: '/cooperation',
      //   component: () =>
      //     import(/* webpackChunkName: "layout" */ '../layouts/MainLayout.vue'),
      //   children: [
      {
        path: "cooperation",
        name: "cooperation",
        component: () =>
          import(/* webpackChunkName: "cooperation" */ "../views/Cooperation/Index.vue"),
      },
      {
        path: "/productService",
        name: "productService",
        component: () =>
          import(/* webpackChunkName: "server" */ "../views/Product/productService.vue"),
      },
      {
        path: "/productPrice",
        name: "productPrice",
        component: () =>
          import(/* webpackChunkName: "server" */ "../views/Product/tradeGeek.vue"),
      },
    ],
  },
  // {
  //   path: '*',
  //   name: '404',
  //   redirect: '/',
  //   // component: () =>
  //   //   import(/* webpackChunkName: "notfound" */ '../views/404.vue'),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
